<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox">
      <div class="officialWebsite" @click="jumpOfficialWeb"></div>
      <div class="telegram" @click="toTg"></div>
      <div class="potato" @click="toPotato"></div>
    </div>

    <div class="downloadBox">
      <img class="qrcodeBox" :src="qrImg" alt="" />
      <div class="tip"></div>
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    toTg() {
      window.open("https://t.me/lieqi91guanfang");
    },
    toPotato() {
      window.open("https://chptdl.org/lieqiguanfang");
    },
    jumpOfficialWeb() {
      window.open("https://eros01.com");
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    position: fixed;
    top: 67px;
    left: 137px;
    height: 102px;
    width: 368px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
  }
  .contactBox {
    position: fixed;
    top: 59px;
    right: 112.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    .officialWebsite {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/officialWebsite.png")
        no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
      cursor: pointer;
    }
    .telegram {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 30px;
      cursor: pointer;
    }
    .potato {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .figure {
    height: 934px;
    width: 818px;
    background: url("./../../../assets/images/pc/figure.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .logoText {
    height: 319px;
    width: 821px;
    background: url("./../../../assets/images/pc/logoText.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 222px;
    top: 263px;
  }
  .downloadBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 137px;
    bottom: 166px;
    .qrcodeBox {
      height: 250px;
      width: 250px;
      background-color: white;
      // border-radius: 10px;
      margin-right: 63px;
    }
    .tip {
      width: 486px;
      height: 250px;
      background: url("../../../assets/images/pc/tip.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
